/* @media only screen and (max-width: 1000px) and (orientation: landscape) { */
    @media only screen and (min-width: 600px) and (max-width: 1200px) {
    
    .cartWrap .template-list-main .item 
    .MuiBox-root:nth-of-type(1) p:nth-of-type(2), .overlay-content .MuiTypography-body1 {
        font-size: 14px !important;
        font-family: "Univers LT Std" !important;
    }

    .cards-list-wrap .card-item-wrap {
        width: 31%;
      }

    .topStack.trophyTopStack button {
        min-width: 200px ;
        font-size: small;
    }

}

@media only screen and (min-width: 600px) and (max-width: 950px) and (orientation: landscape) {
    .splash-container{
        height: 100%;
    }
    .splash-container .typo-logo{
        margin: 30px auto
    }
  }