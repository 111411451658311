@font-face {
  font-family: "Univers LT Std";
  src: url("assets/fonts/UniversLTStd.ttf") format("truetype");
}

@font-face {
  font-family: "Myriad Pro";
  src: url("assets/fonts/MYRIADPRO-REGULAR.woff") format("truetype");
}

@font-face {
  font-family: "Bebas Neue";
  src: url("assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}

.featured_item_desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Univers LT Std";
}

.card-input .search_Icon {
  font-size: 200px !important;
}

.placeholder-gray {
  color: gray;
  /* Change to your desired color */
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* circular progress */
.progress-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.my-autocomplete {
  overflow: hidden;
}

/* Add a border to the Autocomplete dropdown list */
.MuiAutocomplete-paper.MuiPaper-root {
  border: rgba(0, 0, 0, 0.3) 1px solid;
  border-radius: 4px;
  overflow: hidden;
}

.locationSelect
  .custom-select
  > .dropdown-select
  .MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  border: rgba(0, 0, 0, 0.3) 1px solid;
}

.bg_grey_important {
  background-color: rgb(248 251 255) !important;
  border-radius: 40px !important;
}

.bg_grey_important .MuiFormControl-root .MuiInputBase-root input::placeholder {
  color: rgb(112 114 116) !important;
}

/* for edit dog profile*/
@media (min-width: 400px) and (max-width: 850px) {
  .input-item-wrap.q-and-a {
    width: 100% !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .confirm-booking-main {
    flex-direction: column !important;
  }

  .input-toggle-wrap .MuiToggleButtonGroup-root {
    display: block;
    margin-bottom: 2px;
  }

  .input-toggle-wrap .MuiToggleButtonGroup-root .MuiToggleButton-root {
    margin-bottom: 8px;
    margin-inline-end: 8px;
  }
}

/* Hide the scrollbar while allowing scrolling */
.MuiAutocomplete-popper .MuiPaper-root .MuiAutocomplete-listbox {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
}

.appointment-dropdown .textArea .text-height {
  height: 75px;
}

/* Show the scrollbar when the list is scrolled */
.MuiAutocomplete-popper
  .MuiPaper-root
  .MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 5px;
}

.search-team
  .MuiAutocomplete-popper
  .MuiPaper-root
  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Style the Autocomplete input field */
.search-team .MuiAutocomplete-root .MuiOutlinedInput-root {
  border-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 30px !important;
  overflow: hidden;
}

/* Remove the border color change when focused */
.search-team
  .MuiAutocomplete-root
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.3) 1px solid;
}

.search-team
  .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  border-radius: 30px !important;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  /* Adjust the value as needed for your desired border radius */
}

/* Remove the border color change on hover and focus */
.search-team
  .MuiAutocomplete-root
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.MuiAutocomplete-root
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.LocChange:hover {
  cursor: pointer;
}

/* Splash */
.splash-container {
  height: 100vh;
  max-width: 396px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash-container.MuiContainer-root {
  padding: 0 !important;
  box-sizing: unset !important;
}

.splash-container .typo-logo {
  margin: 90px auto;
  text-align: center;
}

.splash-logo {
  margin-bottom: 20px;
  height: 233px;
  width: 230px;
}

.splash-container .typo-logo p {
  font-size: 20px;
  font-weight: 600;
  color: #003087;
}

.splash-container .splash-btn {
  display: flex;
  height: 150px;
  width: 95%;
  flex-direction: column;
  justify-content: space-around;
  gap: 2;
}

.splash-btn button {
  font-size: 18px;
  font-weight: 600;
}

.splash-btn .explore-btn {
  margin-top: 9px;
}

/* splash End */
/* sign up */
.signup-container.MuiContainer-root {
  background-color: #e35205;
  height: 100%;
  padding: 0 !important;
  max-width: 100% !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.signup-container .signup-header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  padding: 25px 0;
  flex: 1 1 auto;
}

.signup-container .signup-main {
  background-color: #fff;
  border-top-left-radius: 54px;
  border-top-right-radius: 54px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-main .social-signup {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.divider {
  background-color: #003087;
  border-radius: 54px;
  width: 132px;
  height: 9px;
  margin: 11px auto !important;
}

.signup-main .signup-tabs-main .signup-tabs {
  background-color: #003087;
  border-radius: 10px;
  width: 591px;
}

.signup-tabs .MuiTabs-flexContainer {
  padding: 5px;
}

.signup-tabs .MuiTab-root {
  color: #fff;
}

.signup-tabs .Mui-selected {
  color: #003087 !important;
  background: white;
  border-radius: 10px;
}

.signup-tabs .MuiTabs-indicator {
  display: none !important;
}

.signup-main .signup-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 591px;
  padding: 20px;
}

.signup-form .form-fullwidth-field {
  display: flex;
  gap: 20px;
}

.form-fullwidth-field .field-section {
  width: 50%;
}

.form-fullwidth-field .field-section label {
  color: #5b5b5b;
  font-family: Myriad Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-transform: capitalize;
}

.signup-form label {
  color: #5b5b5b;
  font-family: Myriad Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-transform: capitalize;
}

.signup-form .MuiFormHelperText-root {
  margin-left: 4px !important;
}

.signup-form .MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.signup-form
  .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate.Mui-checked {
  color: #e35205 !important;
}

.signup-form .remember-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #525252;
  font-family: Myriad Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-transform: capitalize;
}

.forgot-link.MuiTypography-root.MuiLink-root {
  color: #003087;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
}

.signup-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-bottom: 20px;
}

.signup-footer .line {
  height: 0.801px;
  width: 153.934px;
  background: #d8dadc;
}

.signup-footer p {
  color: rgba(0, 0, 0, 0.7);
  font-family: Myriad Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-transform: capitalize;
}

/* signup end */
/* forgot Password */
.forgot-main {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 40px;

  padding: 60px 0;
  min-width: 561px;
}

.forgot-main h3 {
  color: #003087;
  font-family: Myriad Pro;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.3px;
  text-transform: uppercase;
}

.forgot-main .forgot-description {
  color: #525252;
  font-family: Myriad Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.forgot-main .MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

.circle-back-btn.MuiButtonBase-root.MuiButton-root {
  border-radius: 50%;
  border: 1px solid #e35205;
  background: #fff;
  color: #003087;
  height: 35px;
  min-width: 35px;
  width: 35px;
  box-shadow: none;
  margin-bottom: 20px;
}

.circle-back-btn.MuiButtonBase-root.MuiButton-root:hover {
  background: #fff;
  color: #003087;
}

.circle-back-btn .MuiButton-startIcon {
  margin: 0px auto 0px 6px;
}

.forgot-main .send-code {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 5px;
}

.error-danger {
  color: #f54135;
  font-family: Myriad Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
  text-transform: capitalize;
}

.password-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

/* end */
/* Dashboard Start */
.global.MuiBox-root {
  background: #e7eff9 !important;
  height: auto;
}

.dashboard-container.MuiContainer-root.MuiContainer-maxWidthLg {
  margin: 0;
  padding: 20px;
  max-width: 100%;
}

.top-header {
  width: 100%;
  background: #fff;
  border-radius: 40px;
  padding: 15.3px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.top-header .header-btn {
  font-weight: 700;
  padding: 10px 35px !important;
}

.dashboard-container .dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1%;
}

.notification-section.MuiButtonBase-root {
  color: #003087;
  cursor: pointer;
  padding: 0;
  width: max-content;
  min-width: auto;
}

.notification-section .MuiSvgIcon-root {
  height: 27px;
  width: 26px;
  margin-right: -3px;
}

.MuiBadge-badge.MuiBadge-dot {
  background-color: #f14f1b;
  top: 9px;
}

.header-text span {
  color: #f14f1b;
  font-weight: 700;
}

.warning-section {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  padding: 20.2px 21px;
  align-items: center;
  border-radius: 50px;
  background: #fff0e8;
  gap: 11px;
}

.warning-section p {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard-container .dashboard-mid-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2%;
}

.closeNotify-location-sidebar {
  transform: rotate(180deg);
}

.dashboard-mid-section .schedule-section {
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    #32b2ac 62.33%,
    #289691 92.01%,
    #1f807b 100%
  );
}

.schedule-section .visit-schedule {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25px 168px 0px 168px;
  height: 100%;
}

.visit-schedule p {
  color: #fff;
  text-align: center;
  font-family: Bebas Neue;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 111.3%;
}

.dashboard-container .achievements-section {
  width: 49%;
  padding: 25px;
  border-radius: 20px;
  background: #fff;
}

.dashboard-container .featured-section {
  width: 49%;
  padding: 35px;
  border-radius: 20px;
  height: 340px;
  background: #fff;
}

.achievements-section .viewAllBtn,
.trophyBoxHead .viewAllBtn {
  width: 119px;
  height: 37px;
  padding: 6px 23px 7px 23px !important;
}

.top-achieved-list {
  margin-top: 20px;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  background: #fff;
  padding: 30px;
}

.css-jtlhu6-MuiTablePagination-root:last-child {
  width: 100%;
}

.top-achieved-list .achieved-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.achieved-header .heading {
  color: var(--c-1, #003087);
  font-family: "Univers LT Std";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.top-achieved-list .description {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.arrow-btn {
  border-radius: 50% !important;
  padding: 0 !important;
  height: 39px !important;
  min-width: 41px !important;
  margin-right: 10px !important;
}

.holders-item {
  border-radius: 50px;
  background: #e7eff9;
  box-shadow: 0px 1.1576770544052124px 12.734447479248047px 0px
    rgba(0, 0, 0, 0.05);
  height: 63px;
  width: 100%;
  padding: 0 130px 0 35px;
  justify-content: center;
  margin-bottom: 15px;
}

.holders-item.selected {
  background: #003087;
}

.holders-item p {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.holders-item.selected p {
  color: #fff;
}

.holders-item.selected .text {
  color: #003087;
}

.holders-item .item-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.item-content .first-section {
  gap: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.first-section .border-white {
  border: 3px solid transparent;
  border-radius: 50%;
}

.first-section .avatar {
  border: 3px solid #e35205;
  border-radius: 50%;
}

.holders-item.selected .counter {
  background-color: #ffff !important;
}

.holders-item.selected .counter {
  color: #003087;
}

.items-wrapper > .MuiStack-root.holders-item:nth-of-type(3n + 3) .counter {
  background-color: #32b2ac;
}

.items-wrapper > .MuiStack-root.holders-item:nth-of-type(3n + 2) .counter {
  background-color: #003087;
}

.items-wrapper > .MuiStack-root.holders-item:nth-of-type(3n + 1) .counter {
  background-color: #e35205;
}

.first-section .counter {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.simpleBarImg {
  width: 75px;
  height: 40px;
}

.dottedImg {
  width: 75px;
}

.adjBonImg {
  height: 52px;
}

.stepText {
  color: #000;
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
}

.circlePBar .MuiCircularProgress-circle {
  stroke-width: 1 !important;
}

.adjDogImg {
  width: 53.913px;
  height: 58.261px;
  border-radius: 50%;
}

/* End */
/* ---------------------M-styling start---------------- */
html,
body,
#root,
.global {
  min-height: 100vh;
  height: auto;
  scroll-behavior: smooth;
  font-family: "Univers LT Std" !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "Univers LT Std" !important;
}

main > div:nth-of-type(1) {
  display: none;
}

.chatBtn.MuiButtonBase-root {
  position: fixed;
  bottom: 70px;
  right: 35px;
}

.chatBtn.MuiButtonBase-root img {
  width: 70px;
}

.mainSidebar .MuiListItem-root .MuiListItemButton-root {
  width: 43px;
  height: 43px;
  padding: 0px;
  margin: 0 20px;
  min-height: 43px;
  border-radius: 50%;
}

.mainSidebar .MuiPaper-root.MuiDrawer-paper {
  overflow: visible;
}

.mainSidebar .MuiListItem-root:hover .MuiListItemButton-root {
  background: #e35205;
  transition: all ease-in 300ms;
}

.mainSidebar .MuiListItem-root:hover {
  cursor: pointer;
}

.global .mainSidebar .MuiListItem-root .MuiListItemButton-root:hover {
  background: #e35205;
}

.mainSidebar .MuiListItem-root:hover {
  background-color: #f6e6dd !important;
  border-radius: 5%;
}

.mainSidebar .MuiListItem-root:hover .menuitem {
  color: blue !important;
}

.mainSidebar .MuiListItem-root {
  margin-bottom: 20px;
  padding: 7px;
}

.mainSidebar .MuiListItem-root .MuiListItemIcon-root {
  padding-left: 7.29px;
  margin-bottom: 0.5px;
}

.mainSidebar .MuiPaper-elevation {
  background: #003087;
}

.mainSidebar .MuiListItem-root .MuiListItemButton-root .MuiSvgIcon-root,
.mainSidebar .MuiListItemText-root {
  color: #fff;
}

.drawerCloseBtn.MuiButtonBase-root {
  background: #fff0e8;
  width: 37px;
  height: 37px;
  position: absolute;
  right: -19px;
  top: 60px;
  z-index: 9;
}

.drawerCloseBtn.MuiButtonBase-root:hover {
  background: #fff0e8;
}

.drawerCloseBtn.MuiButtonBase-root .MuiSvgIcon-root {
  color: #e35205;
}

.mainSidebar .MuiList-root {
  padding-top: 60px;
  overflow: auto;
}

.mainSidebar .sideLogo {
  transform: translate(-50%, 0px);
  position: absolute;
  top: 5px;
  left: 50%;
}

.sidebar-footer .profileBtn.MuiButtonBase-root {
  width: max-content;
  padding: 0;
  position: absolute;
  bottom: 25px;
  transform: translate(-50%, 0px);
  left: 50%;
}

.sidebar-footer .profileBtnSecond.MuiButtonBase-root {
  width: max-content;
  padding: 0;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0px);
  left: 50%;
  margin-bottom: 10px;
}

.profileBtn > img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.proceedBtn.MuiButtonBase-root.Mui-disabled {
  background: #003087 !important;
  opacity: 0.5;
}

.template-list-main-height {
  height: calc(86vh - 25px);
}

.popups.MuiPaper-root {
  background: #fff0e8;
}

/*---------cart page--------*/
.cartWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* .cartWrap .template-list-main {
  width: 49%;
} */

.cartWrap .template-list-main-cart {
  width: 100%;
}

.cartWrap .template-list-about {
  width: 100%;
  height: 264px !important;
}

.cartWrap .template-list-main .item {
  border-radius: 35px;
  background-color: #e7eff9;
  padding: 9px 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin-top: 12px;
}

.cartWrap .template-list-main .item-cart {
  border-radius: 35px;
  background-color: #e7eff9;
  padding: 9px 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 49%;
}

.cartWrap
  .template-list-main
  .item
  .MuiBox-root:nth-of-type(1)
  p:nth-of-type(1),
.codeBox p {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

.cartWrap
  .template-list-main
  .item
  .MuiBox-root:nth-of-type(1)
  p:nth-of-type(2) {
  color: #003087;
  font-size: 14px;
}

.cartWrap .template-list-main .item .MuiBox-root:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cartWrap .template-list-main .item .MuiBox-root:nth-of-type(2) button {
  width: 32px;
  height: 32px;
  min-width: 32px;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 0;
  border-radius: 50%;
  margin-left: 15px;
}

.checkProcess {
  width: 60%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.checkProcess-cart {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.checkProcess > div,
.purchaseCard {
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(30, 56, 96, 0.2);
  padding: 15px 36px;
}

.checkProcess-cart > div,
.purchaseCard {
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(30, 56, 96, 0.2);
  padding: 15px 36px;
}

.promocard-cart {
  display: flex;
}

.codeBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.codeBox .MuiInputBase-root {
  margin-top: 0;
}

.codeBox .MuiFormLabel-root.MuiInputLabel-root {
  top: -15px;
}

.codeBox .MuiInputBase-root.MuiInput-root:after,
.codeBox .MuiInputBase-root.MuiInput-root:before {
  border-bottom-style: dotted;
}

.summaryBox > p {
  color: #003087;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 1px solid #00000080;
  margin-bottom: 15px;
}

.summaryBox > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.checkoutModal .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  max-width: 440px;
  border-radius: 20px;
}

.checkoutModal .MuiDialogContent-root {
  padding: 0;
  text-align: center;
}

.checkoutModal .checktext {
  padding: 30px 20px;
}

.checktext > p:nth-of-type(1) {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.confirmCheckBtn.MuiButtonBase-root {
  padding: 15px;
  color: #003087;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  border: none;
  width: 100%;
  border-top: 1px solid #0000002e;
  border-radius: 0;
}

.topStack {
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.topStack button {
  color: #003087;
  background: #fff;
  padding: 6px 16px;
  text-transform: capitalize;
  min-width: 104px;
  border-radius: 41px;
  box-shadow: 0px 2px 20px 0px rgba(30, 56, 96, 0.2);
}

.topStack button.active {
  background: #003087;
  color: #fff;
}

.purchaseFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.purchaseFlex > div {
  width: 49%;
}

.purchaseCard {
  margin-bottom: 20px;
}

.purchaseCard .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.purchaseCard .top p:nth-of-type(1) {
  color: #003087;
  font-size: 12px;
  text-transform: capitalize;
}

.purchaseCard .top p:nth-of-type(2),
.purchaseCard .item p:nth-of-type(1) {
  font-size: 17px;
  font-weight: 700;
  color: #000;
}

.purchaseCard .item {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 35px;
  background-color: #e7eff9;
  padding: 9px 29px;
}

/*-------- notification sidebar --------*/
.notifyHead {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeNotify.MuiButtonBase-root {
  width: 30px;
  height: 30px;
  background: #e7eff9;
  border-radius: 50%;
  min-width: 30px;
  color: #003087;
}

.notifySidebar .MuiPaper-root {
  border-radius: 15px 0 0 15px;
}

.MuiBackdrop-root.MuiModal-backdrop {
  background: #a3b0ca80;
}

.notifyHead p:nth-of-type(1) {
  color: #003087;
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
}

.notifyHead button:nth-of-type(3) {
  color: #003087;
}

.notifyWrap {
  position: relative;
}

.notifyWrap .iconWrap {
  z-index: 2;
  margin: 0 10px;
  box-shadow: 0px 4px 4px 0px #00000080;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #e35205;
}

.notifyWrap .item > p,
.notifyWrap .item > .MuiBox-root p:nth-of-type(2) {
  font-size: 14px;
  color: #00000080;
}

.notifyWrap .item > .MuiBox-root p:nth-of-type(1) {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

.notifyWrap .item:before {
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 105px;
  top: 0;
  background: #b3c1db;
}

.notifyWrap .item.unread {
  background: #e7eff9;
  border-bottom: none;
}

/*---------- location sidebar -------------*/
.locationSidebar {
  padding: 30px;
  width: 440px !important;
}

.locationSidebar .MuiPaper-root {
  border-radius: 15px 0 0 15px;
}

.locationHead {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.locationHead > div:nth-of-type(1) .MuiSvgIcon-root {
  width: 15px;
  color: #003087;
  margin-right: 5px;
}

.locationHead > div:nth-of-type(1) p {
  font-family: "Univers LT Std";
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #003087;
  letter-spacing: 0.1px;
}

.locationHead > p:nth-of-type(1) {
  background: #32b2ac;
  color: #fff;
  padding: 15px 20px !important;
  border-radius: 37px;
  font-size: 12px;
  font-weight: 700;
  margin: 0 10px;

  text-align: center;
  font-family: "Univers LT Std";
  font-style: normal;
  line-height: normal;
}

.locationSidebar > button {
  margin-bottom: 20px;
}

.locationSidebar .main-schedule-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 2px 20px 0px #1e386033;
}

.dirBox {
  margin-bottom: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #00000029;
}

.dirBox > div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dirBox > div > div {
  margin-bottom: 20px;
}

/*-------- index phase section -------*/
.phaseFlex {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progresswrap {
  position: relative;
  margin-right: "63px";
}

.phaseFlex .item > img {
  margin: 0 auto;
  display: block;
  width: 44px;
  margin-bottom: 5px;
}

.phaseFlex .item > span {
  font-size: 12px;
  display: block;
  text-align: center;
  margin-bottom: 25px;
}

.circlePBar {
  width: 70px !important;
  height: 70px !important;
  margin: 0 auto;
  display: block !important;
}

.progresswrap > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.phaseFlex .item {
  position: relative;
}

.phaseFlex .item:after {
  content: "";
  position: absolute;
  width: 240%;
  height: 3px;
  border-bottom: 5px dotted #d5dcea;
  right: -220%;
  top: 30px;
}

.phaseFlex .item.done:after {
  border-bottom: 3px solid #d5dcea;
}

.phaseFlex .item:nth-last-of-type(1):after {
  width: 50%;
  right: -50%;
}

/*----------- membership right side----------*/
.memberRight {
  width: 50%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.memBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #003087;
  padding: 30px;
  border-radius: 20px;
}

.memBox div p:nth-of-type(1) {
  font-family: Bebas Neue;
  font-size: 38px;
  font-weight: 400;
  line-height: 42px;
  color: #fff;
}

.memBox div p:nth-of-type(2) {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #fff;
}

.bundleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bundleBox > div {
  width: 49%;
  border-radius: 20px;
  background: #fff;
  text-align: center;
  padding: 30px;
  height: 260px;
}

.bundleBox div p:nth-of-type(1),
.historyBox p:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.bundleBox div p:nth-of-type(2),
.historyBox p:nth-of-type(2) {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

/* .bundleBox div:nth-of-type(2) {
  background: #fff0e8;
} */

.historyBox {
  text-align: center;
  background: #fff;
  padding: 30px 30px;
  border-radius: 32px;
}

/* membership filters */
.membership-filters .dropdown-select {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 50px;
}

.membership-filters .dropdown-select .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.membership-filters .dropdown-select .MuiButtonBase-root {
  border: none !important;
}

.dropItem.MuiMenuItem-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000033;
  background: transparent !important;
  padding: 14px 25px;
}

.expiryBtn.MuiButtonBase-root {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-decoration: underline;
  color: #003087;
  text-transform: capitalize;
  display: block;
  margin: 10px auto;
}

/*------------ achievements -----------*/
.dogSlides {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin: 50px 0 20px;
}

.dogSlides > button {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.dogSlides > button img {
  opacity: 0.5;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 3px solid #fff;
  outline: 3px solid #e35205;
}

.dogSlides > button p {
  display: none;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #000;
}

.dogSlides > button.active img {
  outline: 3px solid #003087;
  transform: scale(1.3);
  margin-bottom: 20px;
  opacity: 1;
}

.dogSlides > button.active p {
  display: block;
}

.earnedFlex {
  margin: 20px 0 50px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 22px;
}

.earnedFlex button {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.earnedFlex button p {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: capitalize;
  color: #000;
}

p.earnTitle {
  font-family: "Univers LT Std";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #003087;
}

.journeyFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.jouneyItem {
  margin-bottom: 25px;
  width: 49%;
  background: #fff;
  border-radius: 32px;
  padding: 30px;
}

.jouneyItem:hover {
  cursor: pointer;
}

.jouneyItem > div:nth-of-type(1) {
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.jouneyItem > div:nth-of-type(1) img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
}

.jouneyItem > div:nth-of-type(1) p {
  margin-top: 6px;
  font-family: "Univers LT Std";
  font-size: 18px;
  font-weight: 700;
  color: #000;
  line-height: 28px;
}

.jonFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.jonFlex:after {
  width: 80%;
  height: 5px;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #e5eaf3;
  z-index: 1;
}

.jonFlex > div {
  position: relative;
}

.jonFlex > div > img:nth-of-type(1) {
  z-index: 2;
  position: relative;
  background: #e5eaf3;
  border-radius: 50%;
}

.jonFlex > div > img:nth-of-type(2) {
  z-index: 3;
  position: absolute;
  right: 0;
  bottom: 2px;
}

.jonFlex > div p {
  position: absolute;
  font-size: 14px;
  right: -65%;
  bottom: 20px;
}

.trophyShelfFlex > .item {
  display: inline-flex;
  flex-direction: column;
  width: 49%;
  background: #fff;
  border-radius: 32px;
  padding: 30px;
  min-height: 272px;
}

.trophyShelfFlex > .item:nth-of-type(2) {
  margin-left: 24px;
  margin-left: 1.5%;
  margin-right: 2px;
  margin-top: 10px;
}

.dogProgress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dogProgress > img {
  margin-right: 30px;
}

.dogProgress > div p:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.dogProgress > div p:nth-of-type(2) {
  font-family: "Univers LT Std";
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #003087;
}

.achieveProgress.MuiLinearProgress-root {
  background-color: #e5eaf3;
}

.achieveProgress.MuiLinearProgress-root .MuiLinearProgress-bar {
  background: #e35205;
}

.dogProgress .linearProgressWrap {
  position: relative;
  padding-top: 20px;
}

.dogProgress .linearProgressWrap span:nth-of-type(1) {
  position: absolute;
  right: 0;
  top: -10px;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #000;
}

.dogProgress > div {
  flex: 1 1 auto;
}

.trophyScroller {
  padding-bottom: 3em;
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 100%;
}

.trophyScroller > img {
  display: inline-block;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  margin-right: 100px;
}

.trophyScroller .progresswrap {
  margin-right: 100px;
  display: inline-block;
}

.trophyShelfFlex > .item:nth-of-type(2) .trophyScroller {
  margin: 35px 0;
}

.dogModal .MuiPaper-root {
  width: 303px;
  border-radius: 20px;
}

.dogModal .modalCont {
  position: relative;
  padding: 70px 0 30px;
  text-align: center;
}

.dogModal .modalCont > button:nth-of-type(1) {
  position: absolute;
  right: -21px;
  top: -5px;
}

.dogModal .modalCont > button:nth-of-type(1) + img,
.dogModal .modalCont > button:nth-of-type(1) + .progresswrap {
  margin-bottom: 20px;
}

.dogModal .modalCont > button:nth-of-type(1) + .progresswrap .circlePBar {
  width: 91px !important;
  height: 91px !important;
}

.dogModal .modalCont > button:nth-of-type(1) + .progresswrap > img {
  width: 84px;
}

.dogModal .modalCont div p:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 23px;
  font-weight: 900;
  line-height: 29px;
  color: #000;
  margin-bottom: 5px;
}

.dogModal .modalCont div p:nth-of-type(1) span {
  margin-left: 5px;
  color: #e35205;
}

.dogModal .modalCont div p:nth-of-type(2) {
  font-family: "Univers LT Std";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.dogModal .modalCont button:nth-of-type(2) {
  padding: 5px 29px !important;
  border-radius: 18px !important;
  margin: 15px 0 0;
}

.modalProgress.MuiLinearProgress-root {
  height: 38px;
  border-radius: 100px;
  background-color: #e5eaf3;
}

.modalProgress.MuiLinearProgress-root .MuiLinearProgress-bar {
  background: #003087;
  border-radius: 30px;
}

.dogModal .linearProgressWrap {
  margin-top: 20px;
  position: relative;
}

.dogModal .linearProgressWrap > span:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.foxWrap {
  text-align: center;
  margin-top: 40px;
}

.foxWrap p {
  font-family: "Univers LT Std";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}

.individualJon {
  text-align: center;
}

.individualJon .box {
  position: relative;
  height: 249px;
}

.individualJon .box .imgBox {
  position: relative;
}

.individualJon .box .imgBox img:nth-of-type(3) {
  position: absolute;
  left: 51%;
  top: 77px;
  transform: translate(-50%, 0px);
}

.individualJon .box.even .imgBox img:nth-of-type(3) {
  left: 48%;
}

.individualJon .box .imgBox img:nth-of-type(2) {
  position: absolute;
  left: 49.5%;
  top: 57px;
  width: 39px;
}

.individualJon .box.even .imgBox img:nth-of-type(2) {
  left: 45.9%;
  top: 45px;
}

.individualJon .box .textBox {
  text-align: left;
  width: 200px;
  position: absolute;
  left: 54%;
  top: 0;
}

.individualJon .box .textBox p:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000;
  margin-bottom: 10px;
}

.individualJon .box .textBox p:nth-of-type(2) {
  font-family: "Univers LT Std";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #000;
}

.individualJon .box.even .textBox {
  text-align: right;
  left: 28.5%;
}

/* profile screen */
.profilArea {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.profilArea > div:nth-of-type(1) {
  position: relative;
  width: 130px;
  height: 130px;
  margin-right: 35px;
}

.profilArea > div:nth-of-type(1) img:nth-of-type(1) {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profilArea > div:nth-of-type(1) img:nth-of-type(2) {
  position: absolute;
  bottom: -31px;
  right: -20px;
}

.profilArea > div:nth-of-type(2) p:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  color: #000;
}

.profilArea > div:nth-of-type(2) p:nth-of-type(2),
.profilArea div:nth-of-type(2) p:nth-of-type(3) {
  font-family: "Univers LT Std";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #003087;
  margin-bottom: 5px;
}

.profilArea > div:nth-of-type(2) p:nth-of-type(4) {
  font-family: "Univers LT Std";
  font-size: 17px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02px;
  color: #e35205;
}

.profilArea > div:nth-of-type(2) p:nth-of-type(2),
.profilArea div:nth-of-type(2) p:nth-of-type(3) {
  font-family: "Univers LT Std";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #003087;
}

.profileProgressWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profileProgressWrap .MuiLinearProgress-root {
  width: 160px !important;
}

.profileProgressWrap .achieveProgress.MuiLinearProgress-root {
  background: #fff;
  height: 7px;
  border-radius: 10px;
}

.profileProgressWrap button {
  padding: 0;
  color: #e35205;
  font-family: "Univers LT Std";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: underline;
  text-transform: capitalize;
  margin-left: 5px;
}

.profileStack.topStack {
  justify-content: center;
  margin-bottom: 30px;
}

.trophyBoxHead {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.trophyBoxProfile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cartWrap.profileCartWrap .template-list-main .item .itemImgWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cartWrap.profileCartWrap .template-list-main .item .itemImgWrap img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.cartWrap.profileCartWrap
  .template-list-main
  .item
  .MuiBox-root:nth-of-type(2)
  > p {
  font-family: "Univers LT Std";
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #e35205;
}

.teamWrap {
  text-align: center;
  margin-right: -40px;
}

.teamWrap img {
  width: 89px;
  height: 89px;
  border-radius: 50%;
  margin-left: -40px;
}

.yourTeamTop p {
  font-family: "Univers LT Std";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #000;
  text-align: center;
  margin: 10px 0 40px;
}

.profileUpload {
  width: 320px;
  height: 320px;
  background: #fff;
  border-radius: 50%;
  position: relative;
}

.profileUpload > img:nth-of-type(1) {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
}

.profileUpload > img:nth-of-type(2) {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
}

.profileUpload:hover {
  cursor: pointer;
}

.addPetWrap {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addPetWrap .profileImg {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.addPetWrap > div {
  width: 50%;
}

.addPetWrap > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.addPetWrap > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.learnDogScreen > p:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #003087;
  margin-bottom: 20px;
}

.learnDogScreen .MuiPaper-root.MuiAccordion-rounded {
  border-radius: 25px;
  border: none;
  box-shadow: none;
  margin-bottom: 20px;
}

.learnDogScreen .MuiPaper-root.MuiAccordion-rounded:before {
  display: none;
}

.learnDogScreen .MuiAccordionSummary-content {
  align-items: center;
}

.learnDogScreen .MuiAccordionSummary-content .MuiSvgIcon-root {
  color: #003087;
  margin-right: 20px;
}

.learnDogScreen .MuiAccordionSummary-content p {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  color: #000;
}

.learnDogScreen .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
  font-size: 30px;
  color: #000;
}

.learnDogScreen .MuiToggleButtonGroup-root button.MuiButtonBase-root {
  background: transparent;
  border: 1px solid #003087;
  font-family: Myriad Pro;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #003087;
  margin: 0 10px;
  width: 92px;
  border-radius: 30px;
  padding: 10px 10px;
  line-height: 1;
}

.learnDogScreen .MuiToggleButtonGroup-root button.Mui-selected {
  background: #003087;
  color: #fff;
}

.learnDogScreen
  .MuiCollapse-wrapper
  .MuiAccordionDetails-root
  .MuiToggleButtonGroup-root {
  background: #f8fbff;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 40px 0;
  border-radius: 20px;
}

.learnDogScreen > button:nth-last-of-type(1) {
  margin-top: 20px;
}

.profileBtnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profileBtnWrap button.confirmCheckBtn {
  width: 50%;
}

.profileBtnWrap button.confirmCheckBtn:nth-of-type(1) {
  color: #d13a00;
  border-right: 1px solid #0000002e;
}

/* ---------------------M-styling-end---------------- */
/* Custom Modal for schedule a visit*/
.dialog-main .MuiPaper-root {
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
  padding: 20px 20px 20px 35px;
}

.dialog-main .dialog-actions {
  justify-content: start;
  gap: 26%;
  align-items: center;
}

.dialog-actions p {
  color: #003087;
  text-align: center;
  font-family: "Bebas Neue" !important;
  font-size: 38px;
  font-weight: 400;
  text-transform: uppercase;
}

.schedule-section-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.schedule-section-wrap .schedule-section-box {
  width: 527px;
  height: 275px;
  border-radius: 32px;
  padding: 154px 60px 0px 60px;
}

.schedule-section-wrap .schedule-section-box:nth-of-type(1) {
  position: relative;
  background: url("./assets/images/banner4.png") center no-repeat;
  background-size: cover;
  background-position: inherit;
}

.schedule-section-wrap .schedule-section-box:nth-of-type(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background: rgba(0, 0, 0, 0.11);
  z-index: 1;
}

.schedule-section-wrap .schedule-section-box:nth-of-type(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.schedule-section-wrap .overlay-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.schedule-section-action {
  position: relative;
  z-index: 2;
}

.schedule-section-wrap .schedule-section-box:nth-of-type(2) {
  position: relative;
  background: url("./assets/images/banner3.png") center no-repeat;
  background-size: cover;
  background-position: inherit;
}

.schedule-section-wrap .schedule-section-box:nth-of-type(1)::before,
.schedule-section-wrap .schedule-section-box:nth-of-type(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.schedule-section-wrap .overlay-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.schedule-section-action {
  position: relative;
  z-index: 2;
}

.schedule-section-box .schedule-section-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.schedule-section-action .action-btn {
  border-radius: 50px !important;
  width: 254px !important;
  padding: 10px !important;
  border: 0 !important;
  font-family: "Univers LT Std" !important;
  font-size: 16px;
  text-transform: inherit;
  font-weight: 700;
}

.schedule-section-action p {
  color: #fff;
  text-align: center;
  font-family: "Univers LT Std" !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.f-21 {
  font-size: 21px !important;
}

.f-14 {
  font-size: 14px !important;
}

.header-text .dialog-link.MuiLink-root {
  text-decoration: underline;
  color: #e35205;
  cursor: pointer;
}

/* Appintment section */
.appointment-container.MuiContainer-root.MuiContainer-maxWidthLg {
  margin: 0;
  padding: 20px;
  max-width: 100%;
}

.appointment-container .appointment-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1%;
  margin-bottom: 20px;
}

.appointment-header .top-header {
  justify-content: flex-start;
  gap: 16px;
}

.appointment-header .top-header .arrow-btn {
  border: 0 !important;
  height: 20px !important;
  min-width: 24px !important;
}

.appointment-header .top-header .header-text,
.trophyShelfWrap .header-text {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.appointment-main {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
}

.appointment-main .field-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
}

.create-account-btn > button {
  width: 100% !important;
}

.appointment-main .error-text {
  position: absolute;
}

.field-section .appointment-dropdown {
  width: 50%;
}

.appointment-dropdown .MuiFormLabel-root.MuiInputLabel-root {
  color: var(--20, #333);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.custom-select {
  background: white;
  border-radius: 36px;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.appointment-dropdown .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.custom-select .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  color: #003087;

  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-select .arrow-btn {
  border: 0 !important;
  height: 32px !important;
  min-width: 32px !important;
}

.custom-select .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  border-radius: 30px !important;
  margin-top: 5px;
}

.custom-select .custom-date-select .MuiButtonBase-root.MuiIconButton-root {
  background: #e7eff9;
  height: 32px;
  color: #e35205;
  width: 32px;
  margin-right: -1px;
}

.custom-select .custom-date-select {
  width: 100% !important;
}

.custom-date-select .MuiInputBase-input.MuiOutlinedInput-input {
  color: #003087;
  font-family: "Univers LT Std";
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
}

.MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-label {
  color: #003087 !important;
  font-weight: 600;
}

.MuiDayCalendar-header
  .MuiPickersCalendarHeader-switchViewButton
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #003087 !important;
}

.MuiPickersArrowSwitcher-root button {
  color: #003087 !important;
  background-color: #e7eff9 !important;
}

.MuiDayCalendar-header span {
  color: #003087 !important;
  font-weight: 600;
}

.MuiDayCalendar-monthContainer button {
  font-weight: 600;
}

.MuiDateCalendar-root {
  width: 540px !important;
}

.MuiDayCalendar-header {
  justify-content: space-around !important;
}

.MuiDayCalendar-weekContainer {
  justify-content: space-around !important;
}

.MuiYearCalendar-root {
  width: 100% !important;
}

.appointment-dropdown .text-field {
  border-radius: 25px;
  background: var(--secondary-white, #fff);
  height: 179px;
  width: 100%;
  padding: 0px !important;
}

.text-field .MuiInputBase-input.MuiOutlinedInput-input {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointment-dropdown .drag-drop-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #003087;
  border-radius: 25px;
  background: var(--secondary-white, #fff);
  height: 179px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  gap: 5px;
}

.drag-drop-box .drop-box-text {
  color: #003087;
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drop-box-text .drop-box-span {
  text-decoration: underline;
  color: #003087;
}

.book-btn,
.book-btn-help-support {
  padding: 17px 28px !important;
  border: 0 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.list-inputs .custom-select .css-1d1r5q-MuiFormHelperText-root.Mui-error {
  margin-left: 13px;
  font-size: 16px;
  padding-left: 21px;
}

.br-50 {
  border-radius: 50px !important;
}

.book-btn svg {
  background: white;
  color: #32b2ac;
  border-radius: 50%;
}

.book-btn-help-support svg {
  background: white;
  color: #e35205;
  border-radius: 50%;
}

.available-slots {
  display: flex !important;
  justify-content: flex-start !important;
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.slots-wrap {
  display: flex;
  margin-top: 10px;
  width: 100%;
  padding: 13px 18px 18px 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 25px;
  background: var(--secondary-white, #fff);
}

.slots-wrap .slot-title {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.slots-wrap .slots-display {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.slots-display .slots {
  border-radius: 25px !important;
  padding: 11.5px 12px 9.5px 12px !important;
}

.slots.active {
  color: #ffff !important;
  background-color: #003087 !important;
}

/* Confirm Booking */
.confirm-booking-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.display-booking-wrap {
  border-radius: 20px;
  width: 100%;
  background-color: #fff;
}

.booking-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 40px 50px 50px 30px;
  width: 100%;
  gap: 37px;
}

.booking-notes-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 23px;
}

.booking-notes {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #fff;
  padding: 25px 34px 34px 47px;
  box-shadow: 0px 2px 20px 0px rgba(30, 56, 96, 0.2);
  width: 100%;
}

.booking-notes .heading {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.booking-notes .description {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  min-height: 70px;
  max-height: 140px;
  overflow: auto;
}

.booking-notes .see-more {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-transform: capitalize;
}

.display-booking-wrap .display-attendee {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 3px;
}

.display-attendee .attendee-header,
.attendee-value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 64%;
}

.attendee-value-styling {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.attendee-header p {
  color: #003087;
  font-family: "Univers LT Std" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  flex-shrink: 0;
}

.attendee-value,
.attendee-value-styling p {
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  flex-shrink: 0;
  margin-top: 5px;
}

.booking-attendee p {
  color: #000;
}

.visitHeader-footer div p:nth-of-type(1) {
  font-size: 24px;
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
}

.attendee-value p,
.visitHeader div p:nth-of-type(2) {
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
}

.visitHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.visitHeader-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.attendee-header p,
.visitHeader div p:nth-of-type(1),
.visitHeader-footer div p:nth-of-type(2) {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.attendee-value p,
.visitHeader div p:nth-of-type(2) {
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
}

.booking-attendee p {
  color: #000;
}

.attendee-header span {
  font-weight: 700;
}

/* Checkout */
.checkout-header {
  margin-bottom: 25px;
}

.checkout-header .radio-main.MuiFormGroup-root {
  gap: 50px;
}

.radio-main .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: var(--rgb-000, #000);
  font-family: Myriad Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.radio-main .MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: #003087;
}

.card-detail-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 30px;
}

.card-detail-wrap .available-slots {
  font-weight: 700;
}

.card-detail-wrap .card-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.card-detail-wrap .appointment-dropdown {
  width: 100%;
}

.input-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.input-section .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
  max-width: 65%;
}

.save-card {
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.save-card p {
  color: var(--rgb-000, #000);
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.14px;
}

.slots-wrap.card-list-detail-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
}

.card-list-detail-wrap .card-list-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.save-card .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #003087;
}

.card-list-detail .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #003087;
}

.card-list-section {
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1005px) {
  .sm_screen_spacing {
    margin-top: 15px;
  }

  .card-list-section {
    display: flex;
    gap: 20px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 25px;
  }
}

.card-list-section .heading {
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.card-list-section .sub-heading {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.color-btn-wrap {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.gray-btn.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineAlways {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.blue-btn.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineAlways {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.red-btn.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineAlways {
  color: #e35205;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.MuiOutlinedInput-input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}

/* Remove the inner and outer spin buttons for WebKit browsers */
.MuiOutlinedInput-input[type="number"]::-webkit-inner-spin-button,
.MuiOutlinedInput-input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.input-section div:nth-child(2) {
  width: 35%;
}

.card-input .MuiInputBase-input.MuiOutlinedInput-input {
  color: #003087;
  font-family: "Univers LT Std" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 16.5px 23px;
}

.card-input {
  background-color: #fff;
  border-radius: 30px;
}

.card-input.search img {
  background: rgb(231, 239, 249);
  border-radius: 50%;
  padding: 6px;
}

.card-input .MuiOutlinedInput-notchedOutline {
  border-radius: 2em;
}

/* Booking terms */
.display-booking-wrap.video-main {
  height: auto;
}

.video-main .booking-wrap {
  padding: 26px;
  gap: 16px;
}

.video-main p {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.booking-wrap .video-wrap {
  width: 609.095px;
  height: 292px;
  border-radius: 20px;
  position: relative;
}

.video-wrap > button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: #fff;
  transform: translate(-50%, -50%);
}

.video-wrap > button svg {
  width: 35px;
  height: 35px;
}

.booking-notes.signature-box {
  margin-top: 0;
  box-shadow: none;
  border-radius: 20px;
  background: var(--secondary-white, #fff);
  height: 108px;
  width: 100%;
  padding: 13px 18px 12px 26px;
  justify-content: center;
  align-items: center;
}

.signature-box .see-more {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.booking-notes-wrap.book-term {
  gap: 34px;
}

.booking-success {
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: flex-end;
  flex-direction: row;
  padding: 10% 35% 0 35%;
  text-align: center;
}

.booking-success .password-success {
  gap: 25px;
}

.link-line.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineAlways {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  text-decoration-color: #003087;
  cursor: pointer;
}

.red-link-line.MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineAlways {
  color: var(--primary-orange, #e35205);
  font-family: Myriad Pro;
  text-decoration-color: #e35205;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  cursor: pointer;
  text-decoration-line: underline;
}

/* Explore */
.explore-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.filter-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
}

.no-space {
  display: flex;
  justify-content: flex-start !important;
}

.filter-section .days-section,
.filter-dropdown-section {
  display: flex;
  flex-direction: row;
  width: 50%;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.days-section .week-days-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.days-section .slots {
  border-radius: 34.414px !important;
  border: 0 !important;
  background: #fff;
  width: 144px !important;
  height: 45px;
  padding: 17px 5px !important;
  box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.1);
  color: var(--primary-blue, #003087);
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.week-days-box .slots {
  border-radius: 50% !important;
  min-width: 49px !important;
  width: 49px !important;
}

.filter-dropdown-section
  .custom-select
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  min-height: 0;
}

.filter-dropdown-section
  .card-input
  .MuiInputBase-input.MuiOutlinedInput-input {
  padding-top: 0;
  padding-bottom: 0;
  height: 3.6rem;
}

.cards-list-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.cards-list-wrap .card-item-wrap {
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 32.2%;
  height: 238.488px;
  padding: 27px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 55px;
  flex-shrink: 0;
}

.card-item-head .viewAllBtn {
  border-radius: 25px !important;

  padding: 2px 16px !important;
}

.card-item-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.card-item-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  width: 100%;
}

.card-item-footer-sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

/* book Service */
.appointment-dropdown.book-service {
  width: 100%;
}

.booking-notes.service-wrap {
  width: 100%;
  gap: 32px;
}

.service-wrap .first-section {
  display: flex;
  gap: 15px;
}

.service-wrap .avatar-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 23px;
  width: 100%;
  overflow: auto;
}

.custom-heading.MuiTypography-root.MuiTypography-body1 {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.service-wrap .avatar-list p {
  color: #000;
  text-align: right;
  font-family: Myriad Pro;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.11px;
}

.display-booking-wrap.book-service-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
}

.book-service-left .service-img {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 236px;
  display: block;
}

.book-service-left .img-section {
  width: 100%;
  position: relative;
  display: inline-block;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
  gap: 84px;
  padding: 35px 50px 0px 50px;
}

.overlay-content .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.overlay-content p {
  color: #fff;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  text-transform: uppercase;
}

.overlay-content .header .heading {
  color: #fff;
  font-family: "Univers LT Std";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
}

.img-footer .heading.MuiTypography-root.MuiTypography-body1 {
  color: #fff;
  font-family: "Univers LT Std";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
}

.overlay-content .header .img-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rating-section {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
}

.rating-section .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #e35205;
}

.booking-notes.service-description {
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  padding-bottom: 18px !important;
}

.pricing-options-unavailable {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* checkout */
.card-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 60px;
}

.credit-remain {
  display: flex !important;
  width: 45%;
  justify-content: space-evenly;
  gap: 30px !important;
  margin-top: 40px !important;
  margin-bottom: 20px !important;
}

.credit-remain p:first-of-type {
  color: #e35205;
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}

.credit-remain p:nth-of-type(2) {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}

/* Buy credit  */
.buy-credit-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.buy-credit-main .available-credit-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.available-credit-list .all-pricing-options-text {
  color: #003087;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  padding-left: 10px;
}

.buy-credit-css {
  background-color: white;
  border-radius: 50px;
  padding: 30px 50px;
  box-shadow: 0px 2px 20px 0px rgba(30, 56, 96, 0.2);
}

.available-credit-list .credit-box {
  border-radius: 50px;
  background: #e7eff9;
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  margin: 7px 0px 7px 0px;
  cursor: pointer;
}

.credit-box .credit-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.credit-box .credit-header-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
}

.credit-header p {
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.credit-box .credit-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.credit-footer p:nth-child(even) {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* dashboard */
.schedule-section.second-section {
  background: #fff;
}

.second-section .main-schedule-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 25px;
  background: white;
  border-radius: 20px;
  height: 100%;
}

.main-schedule-box .class-section-box {
  border-radius: 32px;
  background: #e7eff9;
  display: flex;
  width: 100%;
  height: 60.035px;
  padding: 10px 13px 10px 25px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.main-schedule-box .class-section-box .class-section {
  text-align: left;
}

.class-section-box .class-section .item_description_list_template {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 24px;
}

.class-section-box .class-section .item_description_list_template.font {
  font-size: 14px !important;
}

.main-schedule-box p:first-child {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.class-section-box .class-section p:nth-child(2) {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.class-section-box .arrow-btn {
  border: 0 !important;
  min-width: 37px !important;
  width: 30px !important;
  height: 33px !important;
}

.main-schedule-box .class-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 23px;
}

.remove-document-button {
  background-color: transparent;
  border: none;
  font-size: 13px;
  cursor: pointer;
  margin-left: -4px;
  font-weight: 600;
}

.class-footer .visit-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.trophyShelfWrap {
  height: 81vh;
  overflow: auto;
}

.visit-history .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #003087;
}

.trophyShelfWrap::-webkit-scrollbar {
  display: none;
}

/* visit history */
.history-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  padding-right: 10px;
}

.history-box .display-booking-wrap {
  width: 32.2%;
}

.history-box .attendee-header {
  width: 100%;
}

.attendee-header .with-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 25%;
}

.checked-in.MuiTypography-root.MuiTypography-body1 {
  color: #003087;
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  flex-shrink: 0;
}

.cancel.MuiTypography-root.MuiTypography-body1 {
  color: #e35205;
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.absent.MuiTypography-root.MuiTypography-body1 {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

/* Shop cart */
.shop-cart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 37px;
  background: #e7eff9;
  padding: 8px 14px;
  gap: 10px;
}

.shop-cart p {
  color: #003087;
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 14.6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.shop-cart span {
  font-weight: 700;
}

.top-header.shop-header {
  justify-content: space-between;
  padding: 15px 13px;
}

.shop-header .first-box {
  display: flex;
  gap: 10px;
}

/* Membership */
.membership-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.membership-main .filter-slider-wrap {
  display: flex;
  flex-direction: column;
  width: 49%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.filter-slider-wrap .membership-filters {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  gap: 20px;
}

.filter-slider-wrap .slider-wrap {
  border-radius: 20px;
  background: #fff;
  width: 100%;
  padding: 0px 30px 30px 30px;
  height: auto;
  display: flex;
  height: 344px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.heading.MuiTypography-root.MuiTypography-body1 {
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.slider-wrap .slider-section {
  border-radius: 20px;
  background: #e7eff9;
  width: 100%;

  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.signup_location_dropdown {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px !important;
}

.slider-section .image-section {
  width: 200px;
  height: 208px;
  align-self: stretch;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 0 0 20px;
}

.slider-section .content-section {
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  padding: 40px;
}

.slider-section
  .content-section
  > p.heading.MuiTypography-root.MuiTypography-body1 {
  font-family: "Univers LT Std";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}

.slider-section .content-section .MuiBox-root p {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #003087;
}

.slider-section .content-section button.MuiButtonBase-root {
  font-family: "Univers LT Std";
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  background: #fff;
  color: #003087;
  padding: 10px 30px;
  border-radius: 30px;
  text-transform: capitalize;
  letter-spacing: 0.1px;
}

/* template  */
.template-list-main {
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(30, 56, 96, 0.2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 36px;
  gap: 20px;
}

.template-list-main .MuiBox-root .css-w391ww {
  flex-wrap: wrap;
  justify-content: space-between;
}

.item-details .detail-item-desc,
.top-section .detail-item-desc {
  font-family: "Univers LT Std";
  font-weight: 400;
  font-size: 16px;
}

.scrollable-container-profile {
  width: 100%;
  max-height: 600px;
  overflow: auto;
}

.template-list-main .list-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  max-height: 68vh;
  overflow: auto;
  padding-right: 10px;
}

.list-section .main-schedule-box {
  width: 49%;
  cursor: pointer;
}

/* Item  detail */
.item-detail-wrap {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
}

.item-detail-wrap .top-section {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.top-section .mid-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.mid-section .mid-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.mid-section .item-detail-price {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mid-section .item-detail-value {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.footer-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 36px;
}

.book-btn.item-detail-btn {
  border-radius: 50px !important;
  border: 2px solid #32b2ac !important;
  background-color: transparent !important;
}

.book-btn.item-detail-btn svg {
  background: #32b2ac;
  color: #fff;
}

.loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(79vh);
}

.loader .MuiCircularProgress-root {
  color: #003087;
}

/*profile*/
.profile-main {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
}

.profile-main .dp-section {
  position: relative;
  width: 168px;
  height: 168px;
}

.profile-main .dp-section > img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100 100;
  object-fit: cover;
}

.cart_steps_wrapper .cart_steps_inner .discount_text {
  display: flex;
  justify-content: center;
}

.cart_steps_wrapper .cart_steps_inner .discount_text p {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.discount_text_dark {
  color: #000;
}

.discount_text_blue {
  color: #003087;
  font-weight: 600 !important;
}

.profile-main .dp-section .img-input {
  position: absolute;
  right: -10px;
  top: 13px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.img-input img {
  width: 50px;
  height: 50px;
}

.img-input > input {
  position: absolute;
  height: 50px;
  width: 50px;
  right: 0;
  top: 0;
  opacity: 0;
}

.profile-main .form-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 18px;
}

.form-section .input-item-wrap {
  width: 49%;
  margin-bottom: 4px;
}

.form-section .section-heading {
  color: var(--c-1, #003087);
  font-family: "Univers LT Std";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.18px;
  width: 100%;
}

.input-item-wrap .liability-btn-wrap {
  background: white;
  padding: 13px 23px;
  border-radius: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.single-uploaded-files {
  background-color: white;
  border: none;
}

.single-uploaded-files img {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 50%;
}

.liability-btn-wrap .expiryBtn {
  margin: 0;
  cursor: pointer;
}

.liability-btn-wrap svg {
  color: #32b2ac;
}

.form-inputs .MuiInputBase-root.MuiOutlinedInput-root {
  background: white;
  border-radius: 40px !important;
  padding-left: 21px;
  color: #003087;
  font-weight: 500;
}

.form-inputs .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.form-inputs .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 15.5px 14px;
  font-style: normal;
  font-family: "Univers LT Std";
  font-size: 16px;
  font-weight: 500;
}

.form-inputs .MuiInputBase-input.MuiOutlinedInput-input:-webkit-autofill {
  background: #fff !important;
}

/* Target autofilled input fields */
.form-inputs .MuiInputBase-input.MuiOutlinedInput-input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
  border-radius: 40px !important;
  padding-left: 21px;
  color: #003087;
  font-family: "Univers LT Std";
  font-size: 16px;
  font-weight: 500;
}

.input-toggle-wrap .toggle-btn.MuiButtonBase-root.MuiToggleButton-root {
  background: transparent;
  border-radius: 33px;
  color: #003087;
  width: 118px;
  border: 1px solid #003087;
  padding: 6px 22px;
  text-transform: none;
}

.input-toggle-wrap
  .toggle-btn.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
  color: white;
  background: #003087;
  /* border: 2px solid #003087; */
}

.toggle-btn.MuiButtonBase-root.Mui-disabled.MuiToggleButton-root.Mui-selected {
  border: 2px solid rgba(0, 48, 135, 0.29);
  background: rgba(0, 48, 135, 0.29);
}

.toggle-btn.MuiButtonBase-root.MuiToggleButton-root.Mui-disabled {
  border-color: rgba(0, 48, 135, 0.29);
  color: rgba(0, 48, 135, 0.29);
}

.input-toggle-wrap {
  margin-bottom: 20px;
}

.input-toggle-wrap .MuiToggleButtonGroup-root {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4%;
}

.input-toggle-wrap > p {
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.28px;
  margin-bottom: 20px;
}

.input-item-wrap .liability-btn-wrap.upload {
  border: 2px dashed #003087;
}

.input-item-wrap.q-and-a {
  width: 100%;
}

.input-toggle-wrap .text-field {
  width: 100%;
  background: white;
  height: 100%;
  border-radius: 30px;
  padding: 0px 0px 27px 13px;
}

/* Target autofilled input fields */
.input-toggle-wrap
  .text-field
  .MuiInputBase-input.MuiOutlinedInput-input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
  border-radius: 40px !important;
  padding-left: 21px;
  color: #003087;
}

.input-toggle-wrap .text-field .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.input-toggle-wrap .text-field .MuiInputBase-input.MuiOutlinedInput-input {
  height: auto;
}

.input-item-wrap > label {
  margin-bottom: 15px;
}

.input-item-wrap .custom-select .MuiInputBase-input.MuiOutlinedInput-input {
  padding-left: 32px;
  font-style: normal;
  color: #003087;
  font-family: "Univers LT Std";
}

.dropdown-select .MuiSvgIcon-root {
  display: none;
}

.dropdown-select .MuiSelect-nativeInput {
  height: 100%;
}

.custom-select
  .dropdown-select
  .MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  padding-right: 16px;
}

.custom-select .dropdown-select .MuiAutocomplete-input {
  color: #003087;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  position: absolute;
  left: 50%;
  width: max-content;
  bottom: 0;
  transform: translate(-50%, 0px);
}

.sidebar-footer-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  left: 50%;
  width: max-content;
  bottom: 0;
  transform: translate(-50%, 0px);
  margin-bottom: 15px;
}

.sidebar-footer-horizontal-associated {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  left: 50%;
  width: max-content;
  bottom: 0;
  transform: translate(-100%, 0px);
  margin-bottom: 80px;
}

.sidebar-footer-associated {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  position: absolute;
  left: 50%;
  width: max-content;
  bottom: 0;
  transform: translate(-50%, 0px);
  margin-bottom: 80px;
}

.image-number {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
  border: 2px solid white;
  color: #f14f1b;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
}

.global-modal-main .MuiPaper-root.MuiPaper-elevation {
  border-radius: 20px;
}

.global-modal-main-success .MuiPaper-root.MuiPaper-elevation {
  border-radius: 20px;
  padding: 17px;
  width: 22%;
}

.global-modal-main .MuiDialogContent-root {
  background: #e7eff9;
  width: 100%;
  padding: 37px;
}

.global-modal-main .modal-content-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

/*confrim pop up*/
.global-modal-main .confirm-main {
  padding: 24px;
  max-width: 329px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.global-modal-main .confirm-delete-popup {
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.global-modal-main .confirm-team-box {
  padding: 8px 0px 13px 5px;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirm-team-box .modal-description-confirm {
  color: var(--label-color-light-primary, #000);
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.078px;
}

.modal-description-team {
  color: var(--label-color-light-primary, #000);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.078px;
}

.confirm-main .modal-heading,
.confirm-delete-popup .modal-heading {
  color: var(--label-color-light-primary, #000);
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.confirm-delete-popup .modal-description {
  color: var(--label-color-light-primary, #000);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.078px;
}

.confirm-main .modal-description {
  color: var(--label-color-light-primary, #000);
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.078px;
}

.global-modal-main .MuiDialogActions-root.MuiDialogActions-spacing {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #3c3c435c;
  padding: 0;
}

.global-modal-main .MuiDialogActions-root.MuiDialogActions-spacing button {
  width: 100%;
}

/*end*/
.modal-content-wrap .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}

.modal-header button {
  min-width: 30px;
  height: 26px;
  width: 24px;
  border-radius: 50%;
  background: #fff;
  padding: 0;
}

.modal-header .MuiSvgIcon-root {
  color: #e35205;
  font-size: 20px;
}

.modal-content-wrap .terms-box {
  max-height: 48vh;
  overflow-y: auto;
  border-radius: 25px;
  width: 100%;
  padding: 31px;
  background: var(--secondary-white, #fff);
}

.terms-box p,
.terms-box .liability-font-style {
  color: #333;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.terms-box .MuiTypography-root p {
  height: 50%;
  width: 50%;
  margin: 0;
}

.liabilityWaiverContent .MuiTypography-root p {
  height: 50%;
  width: 100% !important;
  margin: 0;
}

.terms-box .MuiTypography-root p img {
  width: 100%;
}

.terms-box .MuiTypography-root h1 {
  font-size: 16px !important;
}

.modal-content-wrap .clear-link {
  width: 100%;
  text-align: center;
}

.modal-content-wrap .clear-link button {
  color: #000;
  font-family: Myriad Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.17px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.profile-btn-divider {
  width: 1px;
  background: #3c3c435c;
  height: 48px;
}

.confirm-main-wrap .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  border-radius: 14px;
}

.confirm-main-wrap .MuiButtonBase-root.MuiButton-root {
  padding: 12px;
  margin: 0;
}

.confirm-main-wrap .red-btn {
  color: var(--rgb-209580, #d13a00);
  text-align: center;
  text-transform: capitalize;
  font-feature-settings: "case" on;
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.confirm-profile .red-btn {
  color: var(--rgb-209580, #d13a00);
  text-align: center;
  text-transform: capitalize;
  font-feature-settings: "case" on;
  font-family: "Univers LT Std";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.confirm-profile .blue-btn {
  color: var(--primary-blue, #003087);
  text-align: center;
  font-feature-settings: "case" on;
  font-family: "Univers LT Std";
  text-transform: capitalize;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.confirm-main-wrap .blue-btn {
  color: var(--primary-blue, #003087);
  text-align: center;
  font-feature-settings: "case" on;
  font-family: "Univers LT Std";
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.scrollable_container {
  max-height: 250px;
  overflow: auto;
}

.scrollable_container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.scrollable_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d9d9d9;
}

.scrollable_container::-webkit-scrollbar-track {
  background-color: transparent;
}

.Available_Credits_Border {
  padding-top: 0px !important;
  border-radius: 20px;
  border: 1px solid rgba(0, 48, 135, 0.15);
  background: var(--Secondary-white, #fff);
}

/*signature pad*/
.signature-canvas {
  width: 100%;
  height: 100px;
}

.book-btn.Mui-disabled.MuiButton-root {
  background-color: rgb(50 178 172 / 65%) !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper {
  border-radius: 30px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper {
  border-radius: 30px;
  position: absolute;
  top: 6px;
  width: 100%;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  background-color: #b3c1db !important;
}

/*featured slider fk*/
.slider-wrap > div {
  width: 100%;
  overflow: hidden;
  justify-content: center;
}

.slider-wrap .MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  background: #e35205;
}

/*teams modal*/

.teams-main-wrap
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper {
  border-radius: 20px;
  width: 400px;
  padding: 17px 20px 50px 20px;
}

.confirm-profile
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper {
  border-radius: 20px;
  width: 400px;
  padding: 0px 0px 0px 10px;
}

.teams-main-wrap .modal-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
}

.modal-header.profile-content .close-button {
  margin: 0;
}

.modal-header.profile-content .close-button > svg {
  color: #003087;
}

.modal-header .close-button {
  background: rgb(231, 239, 249);
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-main .team-main-heading {
  color: #000;
  font-family: "Univers LT Std";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.team-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.team-main .teams-content {
  width: 100%;
}

.teams-content .custom-select .MuiOutlinedInput-notchedOutline {
  border-radius: 30px;
  border: 1px solid rgb(0 0 0 / 30%);
}

.team-main .book-btn.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  border-radius: 30px !important;
  padding: 8px 28px !important;
}

.liability-btn-wrap .expiryBtn > div {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.expiryBtn .img-input > input {
  height: 100%;
  width: 100%;
}

.team-main > svg {
  height: 50px;
  width: 50px;
  color: #e35205;
}

.team-main .teams-content .invite-para {
  color: #000;
  text-align: center;
  font-family: "Univers LT Std";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.team-main.invite-main {
  gap: 20px;
}

.profile-mid-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.profile-mid-section p,
.MuiTypography-root.MuiTypography-body1 .see-more {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;
}

.profile-mid-section .see-more {
  cursor: pointer;
}

.profile-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.profile-no-data button {
  background: #e35205;
  color: #fff;
  border-radius: 30px;
  padding: 10px 70px;
}

.profile-no-data button:hover {
  background: #e35205;
  color: #fff;
}

.profileProgressWrap .MuiTypography-root.MuiTypography-body1 {
  color: #e35205 !important;
  font-family: Univers LT Std !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-left: 10px;
}

.logoWithName {
  width: 230px;
  height: 50px;
}

/* Hover styling for list items */
.menu-list .list-item-link .list-on-hover {
  border-bottom-left-radius: 5% !important;
  border-bottom-right-radius: 5% !important;
}

.list-item-link .list-on-hover {
  background-color: #f6e6dd !important;
  display: block;
  padding: 2px 0px 0px 10px !important;
  list-style-type: none;
  cursor: pointer;
  color: #003087;
}

.list-item-link .list-on-hover li {
  display: block;
}

.mainSidebar .MuiListItem-root:hover .list-item-text,
.list-item-text:hover {
  color: #003087 !important;
}

.service-btn {
  width: 186.22px;
  height: 41.25px;
  position: absolute;
  top: 495.892578125px;
  left: 1060.9296875px;
  padding: 6px 15px;
  border-radius: 45px;
  gap: 10px;
  background-color: #e7eff9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-btn2 {
  width: 187.44px;
  height: 41.25px;
  top: 495.892578125px;
  left: 1270.8359375px;
  padding: 6px 15px 6px 15px;
  border-radius: 45px;
  gap: 10px;
  position: absolute;
  background-color: #e7eff9;
  display: flex;
}

.locationHead > div:nth-of-type(1) {
  border-radius: 20px;
  box-shadow: 0px 0px 8px 0px #0000001a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  max-width: 190px;
  word-wrap: break-word;
  height: 44px;
}

.locationHead > p:nth-of-type(1) {
  background: #32b2ac;
  color: #fff;
  padding: 10px 18px;
  border-radius: 37px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  font-family: "Univers LT Std";
  font-style: normal;
  line-height: normal;
}

.locationHead > p:nth-of-type(2) {
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  color: #d13a00;
  font-family: "Univers LT Std";
  font-style: normal;
  line-height: 14px;
}

.class-section-box .class-section p:first-child {
  color: var(--rgb-000, #000);
  font-family: "Univers LT Std";
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
}

.dirBox p:nth-of-type(1) {
  font-family: "Univers LT Std";
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0em;
  color: #003087;
}

.dirBox p:nth-of-type(2) {
  font-family: "Univers LT Std";
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0em;
  color: #000;
}

.dirBox button {
  color: #003087;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: -10px;
}

.notifyHead button:nth-of-type(2) {
  color: #000;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;

  font-family: "Univers LT Std";
  font-style: normal;
  line-height: 16px;
}

.notifyWrap .item.unread > p,
.notifyWrap .item.unread > .MuiBox-root p:nth-of-type(2) {
  color: #000;
  font-weight: bold;
}

.notifyWrap .item {
  padding: 30px 30px 30px 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #00000029;
}

.header-text.MuiTypography-root.MuiTypography-body1 {
  color: var(--primary-blue, #003087);
  font-family: "Univers LT Std" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.header-text-black.MuiTypography-root.MuiTypography-body1 {
  color: var(--primary-blue, black);
  font-family: "Univers LT Std" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  word-spacing: 2.2px;
}

.holders-item .dogname {
  color: #003087;
  font-weight: bold;
}

.holders-item .text {
  color: #fff;
  margin-right: -15px;
}

.topStack.trophyTopStack button {
  min-width: 300px;
  padding: 10px 26px;
  letter-spacing: 0.1px;
  margin-bottom: 30px;

  font-family: "Univers LT Std";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
}

.followus {
  color: #000;
  font-weight: 700;
  width: 106.958px;
  font-family: "Univers LT Std";
  font-size: 14px;
}

.social {
  margin-top: 5px;
}

.loader-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #000000;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container .loader-img {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-text {
  color: #f54135 !important;
}

.error-text-sub {
  color: #f54135 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-minus-35 {
  margin-top: -35px !important;
}

.mt-minus-18 {
  margin-top: -18px !important;
}

.mt-minus-38 {
  margin-top: -38px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.text-wrap {
  white-space: normal;
}

.width-styling {
  width: 49% !important;
  margin-top: 20px !important;
}

.appointment-main .availiablity-text {
  display: flex !important;
  justify-content: flex-start;
}

.header-text-blue {
  font-family: "Univers LT Std" !important;
  color: #003087 !important;
  line-height: 21.6px !important;
}

.text-blue {
  color: #003087 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.f-16 {
  font-size: 16px !important;
}

.edit_profile_form {
  width: 88%;
}

.signup_location_popup .MuiDialog-container .MuiPaper-root {
  width: 44%;
  border-radius: 25px;
  padding: 14px;
}

.signup_location_popup .MuiDialog-container .MuiPaper-root .title {
  font-weight: 700;
  line-height: 24px;
  color: #003087;
  text-align: center;
}

.signup_popup .MuiDialog-container .MuiPaper-root {
  width: 22%;
  border-radius: 25px;
  padding: 4px;
}

.signup_popup .MuiDialog-container .MuiPaper-root .title {
  font-weight: 700;
  line-height: 24px;
  color: #003087;
  text-align: center;
}

.signup_location_popup_label {
  font-family: "Univers LT Std" !important;
  font-size: 16px !important;
  color: black !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: -0.14px !important;
  margin-bottom: 10px !important;
}

.signup_location_popup_setBTN {
  background: #003087 !important;
  border-radius: 50px !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  width: 100%;
  padding: 10px 0 !important;
}

.signup_location_select {
  border-radius: 25px;
  border: 1px solid #e7eff9;
}

.signup_location_select .MuiInputBase-root .MuiSelect-select {
  padding: 14px 0;
}

.checkout_shop_form {
  padding: 30px 40px 40px 40px;
  background-color: #ffff;
  border-radius: 15px;
  margin-top: 30px;
}

.checkout_shop_form .payment_total {
  font-family: "Univers LT Std";
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  padding-bottom: 20px;
}

.checkout_shop_form_inner {
  background-color: #e7eff9;
  border-radius: 0px 0px 20px 20px;
}

.custom_radio_btn_card {
  border-radius: 20px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

@media screen and (max-width: 1200px) {
  .form-section .input-item-wrap {
    width: 100%;
  }

  .cartWrap {
    flex-direction: column;
  }

  .cartWrap .template-list-main {
    width: 100%;
  }

  .history-box .display-booking-wrap {
    width: 100%;
  }

  .overlay-content,
  .booking-notes {
    padding: 35px 18px 18px 18px;
  }
}

.align-center {
  text-align: center !important;
}

.alert-button-control {
  justify-content: space-evenly !important;
  padding: 0px 8px !important;
}

.alert-button-control .vertical-divider {
  height: 50px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.alert-title {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #000000 !important;
}

.alert-decription {
  color: #000000 !important;
}

.font-17 {
  font-size: 17px !important;
}

.text-black {
  color: #000000 !important;
}

.no-hover-btn:hover {
  background-color: transparent !important;
}

.my-schedule-viewAllBtn {
  padding: 6px 23px 7px 23px !important;
}

/*-----------Bugs css starts-----*/
.today-schedule-section {
  background-color: #e7eff9;
  padding: 10px;
  border-radius: 18px;
  height: 205px;
}

.today-schedule-section .text-blue {
  margin-left: 0 !important;
}

.trophy-slider {
  padding: 20px;
}

.trophy-slider .slick-track {
  display: flex !important;
  align-items: center !important;
}

.trophy-slider .slick-prev:before,
.slick-next:before {
  color: rgb(50, 178, 172) !important;
  font-size: 25px !important;
}

.top-achieved-list .leaderBoard_content_wrapper {
  height: 250px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  height: 10px !important;
  width: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.801);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.checkProcess.cart_select_dog > div {
  padding: 3px;
}

.sidebar-footer-horizontal-associated {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  left: 50%;
  width: max-content;
  bottom: 0;
  transform: translate(-100%, 0px);
  margin-bottom: 80px;
  margin-left: 7px;
}

.sidebar-footer-associated {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  position: absolute;
  left: 50%;
  width: max-content;
  bottom: 0;
  transform: translate(-50%, 0px);
  margin-bottom: 60px;
}

.image-number {
  position: absolute;
  right: -10px;
  /* Adjust based on your design */
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  /* Or any color you prefer */
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
  border: 2px solid white;
  /* Adjust as needed */
  color: #f14f1b;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
}

.profileBtn {
  position: relative;
  /* Needed for absolute positioning of children */
  /* Other styles */
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  /* To vertically center images */
}

.adjDogImg {
  height: 50px;
  /* Adjust based on your image size */
  width: 50px;
  /* Adjust based on your image size */
  object-fit: cover;
  border: 2px solid #003087;
}

.trophy-view-adjustment .adjust-trophy-aspect {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.progresswrapp {
  padding: 14px;
}

.progresswrapp .inner-trophy-image {
  max-height: 150px;
  width: 100px;
  object-fit: cover;
}

.progresswrapp .individual-trophy {
  max-width: 100%;
}

.trophy-view-adjustment .individual-trophy {
  max-width: 300px !important;
  max-height: 300px;
  object-fit: cover;
}

.adjDogImg:not(:first-child) {
  margin-left: -43px;
  /* Adjust the overlapping */
}

.last-image {
  position: relative;
  /* To position the number span relative to this */
}

.associatedDogDefaultImage {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.associatedDogImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(231, 239, 249, 0.938) !important;
  margin-bottom: 2px !important;
  padding: 8px;
}

.associatedDogImageContainer:hover {
  background: rgba(206, 216, 228, 0.938) !important;
}

.adjDogImgPopup {
  border-radius: 50%;
  height: 35px;
  margin-right: 10px;
  object-fit: cover;
  width: 35px;
  cursor: pointer;
}

.associatedDog-menu .MuiMenu-paper .MuiMenu-list {
  max-height: 75vh !important;
  overflow: auto;
}

.earnedFlex .trophy_card {
  padding-top: 17px;
  width: 150px;
  height: 180px;
  text-align: center !important;
}

.trophy-view-adjustment {
  width: 140px;
  text-align: center;
}

.trophy-view-adjustment .adjDogImg {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
  /* border-radius: 50%; */
}

.MuiBackdrop-root {
  visibility: hidden !important;
}

.custom-select
  .MuiFormControl-fullWidth.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 17px !important;
}

.clear-data-close-icon-search {
  padding: 0 !important;
}

.clear-data-close-icon-search.icon {
  width: 26px !important;
}

.hamburger-cont .hamburger-icon-btn {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: #fff0e8;
  box-shadow: 1px 0px 6px -1px rgba(0, 0, 0, 0.13);
}

.hamburger-cont .hamburger-icon-btn:hover {
  background-color: #fff0e8;
}

.hamburger-cont .hamburger-icon-btn svg {
  fill: #e35205;
}

.hamburger-cont {
  bottom: -18px;
  left: -21px;
  z-index: 10;
  -webkit-transition: all 0.4s ease;
}

.responsive_top_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.availableCredits .availableCredits-popup-content {
  border: 1px solid rgba(0, 48, 135, 0.15);
  padding: 12px 15px;
  border-radius: 20px;
  max-height: 264px;
  overflow: auto;
}

.explore-filter {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 5px;
}

.explore-filter-date-picker-close {
  padding: 4px;
  background-color: #e7eff9;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  margin: 11px 10px 0 0;
  cursor: pointer;
}

.availableCredits
  .availableCredits-popup-content
  .availableCredits-popup-content-inner {
  margin: 0;
  padding: 0;
}

.availableCredits
  .availableCredits-popup-content
  .availableCredits-popup-content-inner
  li {
  padding: 10px;
  list-style-type: none;
  font-family: "Univers LT Std";
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.contract-signature-dialog .MuiPaper-root {
  border-radius: 20px;
}

.question-container-background {
  background-color: #e35205 !important;
  height: 100%;
  padding: 0 !important;
  max-width: 100% !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.question-dialog-white {
  background-color: white;
  width: 100%;
  height: 70vh;
  position: absolute;
  top: 40%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

@media screen and (min-width: 1200px) {
  .d-md-block {
    display: block !important;
  }

  .d-md-none {
    display: none !important;
  }

  .days-section .slots {
    width: 100% !important;
    height: 54px;
    font-size: 15px;
  }
}

.link-text {
  text-decoration: underline;
  color: #003087;
  cursor: pointer;
}

@media screen and (max-width: 1180px) {
  .dashboard-container .dashboard-mid-section {
    display: block;
    width: 100%;
    gap: 2%;
  }

  .global-modal-main-success .MuiPaper-root.MuiPaper-elevation {
    border-radius: 20px;
    padding: 17px;
    width: 34%;
  }

  .days-section .slots {
    width: 100% !important;
    height: 54px;
    font-size: 15px;
  }

  .locationSidebar {
    padding: 16px;
    width: 340px !important;
  }

  .dashboard-container .achievements-section,
  .dashboard-container .featured-section {
    width: 100%;
    border-radius: 20px;
    height: auto;
    background: #fff;
    margin-top: 25px;
  }

  .list-section .main-schedule-box {
    width: 100%;
    cursor: pointer;
  }

  .filter-section {
    display: block;
    width: 100%;
    gap: 50px;
  }

  .filter-section .days-section,
  .filter-dropdown-section {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .md-d-none {
    display: none !important;
  }

  .global {
    position: relative;
    display: block !important;
  }

  .global .mainSidebar {
    position: absolute;
  }

  .dashboard-container.MuiContainer-root.MuiContainer-maxWidthLg {
    padding: 10px;
  }
}
.asterisk {
  color: #e35205 !important;
}
.sign-up-pop-up {
  font-size: 15px !important;
  font-weight: 700 !important;
  letter-spacing: -0.81px !important;
  line-height: 22px !important;
}

.get-available-credit-btn {
  color: #003087;
  background-color: #b1bddb50;
  border-radius: 15px;
  padding: 4px 10px;
  cursor: pointer;
  margin: -5px 2px;
}

.header-blue-40 {
  font-family: "Myriad Pro", sans-serif !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

.location-details-label {
  color: #525252;
  font-family: "Myriad Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.location-details-value {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Myriad Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.signatureCanvasSignaturePage {
  height: 103px;
  width: 450px;
  border: 2px solid rgba(0, 48, 135, 0.5);
  border-radius: 10px;
  padding: 15px;
  margin-right: 20px;
  float: left;
  background-color: #ffffff;
}

.header-blue-24 {
  font-family: "Myriad Pro", sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #003087 !important;
}

.liabilityWaiverText {
  color: #000;
  font-family: "Myriad Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
